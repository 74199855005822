import React from 'react';
import block from "bem-css-modules";
// import RenderField from 'components/RenderField';

import input from "../../../styles/components/Utility/Forminput.module.scss";

export default function FormInput({isFocus, formId, disabled, modifier, onChange, items, type, value, pattern, placeholder, maxLength, name }) {
  
  block.setSettings({
    modifierDelimiter: "--",
  });

  const form = block(input);

    return (
      <div className='formInput' className={form('')}>
        <input          
          className={`${form('field')}` + ' ' + `${modifier && form(`field--${modifier}`)}`}
          isFocus={isFocus}
          itemID={formId}
          name={name ? name : 'textinput'} 
          // component={RenderField} 
          type={type ? type : 'text'}
          placeholder={ placeholder ? placeholder : '' }
          maxLength={ maxLength ? maxLength : null  }
          value={value ? value : ''}
          pattern={pattern ? pattern : null}   

          onChange={onChange}
          // modifier={modifier}
          disabled={disabled}
        />
      </div>
    );  
}
