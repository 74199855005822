import React from "react";
import block from "bem-css-modules";
import text from "../../styles/components/Textblock.module.scss";
import isEmpty from "lodash/isEmpty";

export default function TextBlock({column, texts, files, hideImage}) {

  block.setSettings({
    modifierDelimiter: "--",
  });

  const textBlock = block(text);
  return (
      <section className={`${textBlock('')}` + ' ' + `${hideImage && text['textBlock--hideImage']}`}>
        <div className={`${textBlock("item")}` + ' ' +  `${ column && textBlock(`item--${column}`)}`}>
        <p>  {texts && (
          <div
            dangerouslySetInnerHTML={{
              __html: `${texts}`
            }}
          />
        )}
        </p>

          {/* <ul>
            <li>Bullet</li>
            <li>Bullet</li>
            <li>Bullet</li>
            <li>Bullet</li>
          </ul>
        */}
          {!isEmpty(files) && files.map((item, key)=>(
              <a key={key} href={item.Url} target="_blank" download >{item.Title}</a>
          ))

          }
          {/* <a href='#' download>Q3_Report.pdf</a>
          <a href='#' download>nextory_cappelen_image.jpg</a>  */}

        </div>
      </section>
  );
}