import React from 'react';
import block from "bem-css-modules";
import hero from "../../styles/components/Hero.module.scss";

function Hero({ quotes, modifiers, label, quoteWriter, image }){
    
    block.setSettings({
        modifierDelimiter: "--",
      });
    
    const banner = block(hero);
    
    // let variant = {};			
    // variant[`${modifiers}`] = true;

    return(
        <div className={banner("")} data-numbers={label}> 
        {/* <div className={`${banner("items")} ${modifiers && banner(`items--${modifiers}`)}`}>
                <h1>Hero Banner</h1>
            </div> */}            
            <div className={`${banner("wrapper")} ${ quotes && banner("wrapper--quotes")}`}> 
            <span className={banner('headerTextLabel')}>{label}</span>
                <div  className={`${banner("banner")}`} style={{backgroundImage: `url(${image})`}}>
                { quotes && <div className={banner("quotes", {tablet: true})}>
                <p className={banner("text")}>{quotes || '' }
                </p> <span className={banner("author")}>{quoteWriter || ''}</span></div>  }
                </div>
                { quotes && <div className={banner("quotes", {mobile: true})}>
                <p className={banner("text")}>
                {quotes || '' }
                </p><span className={banner("author")}>{quoteWriter || ''}</span></div>  }
            </div>
        </div>
    )
}

export default Hero;