
import React from 'react';
import block from "bem-css-modules";
import options from "../../../styles/components/Utility/Formoptions.module.scss";

export default function FormOptions({modifier, label, children, message})  {

    block.setSettings({
        modifierDelimiter: "--",
      });
    
    const form = block(options);
      
    // const modifier = modifier || '';

    return (
      <div className={`${form('')}` + ' ' + `${modifier && options[`formOptions--${modifier}`]}`}>
        <div className={form('legend')}>
          <div className={form('label')}>{label} </div>        
        </div>
        <div className={form('main')}>{children}</div>
        <div className={form('message')}>{message}</div>
      </div>
    );
  }