import React, { useState, useRef } from "react";
import block from "bem-css-modules";
import CheckBox from "../Utility/CheckBox";
import FormOptions from "../Utility/FormOptions";
import FormInput from "../Utility/FormInput";
import ReCAPTCHA from "react-google-recaptcha";
import button from "../../styles/components/Button.module.scss";
import subscription from "../../styles/components/Subscribe.module.scss";

const SubscribeCard = function ({
  spaceTop,
  spaceBottom,
  uniqueIdentifier,
  successUrl,
  failureUrl,
  successMsg,
  failureMsg,
}) {
  block.setSettings({
    modifierDelimiter: "--",
  });

  const submitRef = useRef(null);

  const btn = block(button);
  const sub = block(subscription);

  const [email, setEmail] = useState("");
  const [newsSelected, setNewsSelected] = useState([]);
  const [informationTypes, setInformationTypes] = useState("");
  const [captcha, setCaptcha] = useState("");
  const [isOn, setisOn] = useState("");
  const [validation, setValidation] = useState({
    language: true,
    news: true,
    email: true,
    captcha: true,
    emailEmpty: true,
  });

  let language = [
    {
      name: "English",
      value: "en",
    },
    {
      name: "Swedish",
      value: "sv",
    },
  ];

  const handleToggle = (i, u) => {
    let valid = validation;
    valid.language = true;
    setValidation({ ...valid });
    setisOn(u.value);
  };

  let news = ["Press releases", "Financial reports"];

  const selectItem = (i) => {
    let informationString = "";
    let valid = validation;
    const langs = newsSelected;
    if (langs.includes(i)) {
      const index = newsSelected.indexOf(i);
      langs.splice(index, 1);
    } else {
      langs.push(i);
    }
    langs.map((lang, i) => {
      if (lang == "Press releases") {
        i < 1
          ? (informationString = "prm")
          : (informationString = informationString + ",prm");
      } else {
        i < 1
          ? (informationString = "kmk")
          : (informationString = informationString + ",kmk");
      }
    });

    if (informationString) {
      valid.news = true;
    } else {
      valid.news = false;
    }

    setValidation({ ...valid });
    setNewsSelected([...langs]);
    setInformationTypes(informationString);
  };

  const handleOnChange = (event) => {
    let valid = validation;
    let email = event.target.value;

    if (event.target.value.length === 0) {
      valid.emailEmpty = false;
      valid.email = true;
    } else {
      valid.emailEmpty = true;
      validateEmail(email) ? (valid.email = true) : (valid.email = false);
    }

    setEmail(email);
    setValidation({ ...valid });
  };

  function onChangeCaptcha(captcha) {
    let valid = validation;
    valid.captcha = true;
    setValidation({ ...valid });
    setCaptcha(captcha);
  }

  function validateEmail(email) {
    let reg = /\S+@\S+\.\S+/;
    if (reg.test(email)) {
      return true;
    } else {
      return false;
    }
  }

  function validateFields() {
    let valid = validation;

    if (email) {
      validateEmail(email) ? (valid.email = true) : (valid.email = false);
    } else {
      valid.emailEmpty = false;
      valid.email = true;
    }

    isOn ? (valid.language = true) : (valid.language = false);
    informationTypes ? (valid.news = true) : (valid.news = false);
    captcha ? (valid.captcha = true) : (valid.captcha = false);
    setValidation({ ...valid });
    for (const [key, value] of Object.entries(valid)) {
      if (!value) {
        return false;
      }
    }
    return true;
  }

  async function onSubscribe() {
    if (!validateFields()) {
      return;
    } else {
      submitRef.current.click();
    }
  }
  return (
    <div className={sub("")}>
      {successMsg && (
        <h3 className={sub("status")}> Thank you for subscribing! </h3>
      )}
      {failureMsg && (
        <h3 className={sub("status")}> You've already subscribed to this newsletter! </h3>
      )}
      <div
        className={
          `${sub("holder")}` +
          " " +
          " " +
          `${spaceTop && sub("holder--spaceTop")}` +
          " " +
          `${spaceBottom && sub("holder--spaceBottom")}`
        }
      >
        <div className={sub("box")}>
          <form
            method="post"
            name="SubscriptionForm"
            id="CisionSubscriptionForm"
            action="//publish.ne.cision.com/Subscription/SubscribeWithCaptcha"
          >
            <input
              type="hidden"
              name="subscriptionUniqueIdentifier"
              value={uniqueIdentifier}
            />
            <input
              type="hidden"
              name="redirectUrlSubscriptionSuccess"
              value={successUrl}
            />
            <input
              type="hidden"
              name="redirectUrlSubscriptionFailed"
              value={failureUrl}
            />
            <input type="hidden" name="Language" value={isOn} id="LanguageEn" />
            <input
              type="hidden"
              name="informationtype"
              value={informationTypes}
              id="informationType"
            />
            <input
              type="hidden"
              name="Replylanguage"
              value={"en"}
              id="replyLanguage"
            />
            <section className={sub("section")}>
              <h2 className={sub("head")}>Choose a language</h2>
              {language.map((u, i) => (
                <div
                  className={sub("select")}
                  onClick={() => handleToggle(i, u)}
                  key={i}
                >
                  <p className={sub("item")}>{u.name}</p>
                  <CheckBox active={isOn === u.value ? "active" : ""} />
                </div>
              ))}
              {!validation.language && (
                <p className={sub("error")}>
                  Please choose your preferred language.
                </p>
              )}
            </section>

            <section className={sub("section")}>
              <h2 className={sub("head")}>
                What would you like to receive emails about?
              </h2>
              {news.map((i) => (
                <div
                  className={sub("select")}
                  onClick={() => selectItem(i)}
                  key={i}
                >
                  <p className={sub("item")}>{i}</p>
                  <CheckBox active={newsSelected.includes(i) ? "active" : ""} />
                </div>
              ))}
              {!validation.news && (
                <p className={sub("error")}>Pick at least one.</p>
              )}
            </section>

            <section className={sub("section")}>
              <h2 className={sub("head")}>Email</h2>
              <FormOptions>
                <FormInput
                  items={"email"}
                  name={"Email"}
                  placeholder={"Email Address"}
                  onChange={handleOnChange}
                  value={email}
                  modifier={"subscribe"}
                />
                {!validation.emailEmpty && (
                  <p className={sub("error")}>Please enter an email.</p>
                )}
                {!validation.email && (
                  <p className={sub("error")}>
                    Double check you email address.
                  </p>
                )}
              </FormOptions>

              <ReCAPTCHA
                className={sub("captcha")}
                sitekey={process.env.GOOGLE_CAPTCHA_SITE_KEY}
                onChange={onChangeCaptcha}
              />
              {!validation.captcha && (
                <p className={sub("error")}>That didn't work out. Try again.</p>
              )}
              <button
                type="submit"
                ref={submitRef}
                style={{ display: "none" }}
              />
              <button
                onClick={(e) => {
                  e.preventDefault();
                  onSubscribe();
                }}
                className={`${btn("", { primary: true })} ${sub(
                  "subscribeCta"
                )}`}
              >
                {" "}
                Subscribe{" "}
              </button>
            </section>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SubscribeCard;
