

export default function IconTick(){
    return (
      <svg width='12px' height='18px' viewBox='0 0 12 14'>
        <path d='M4.50575693,14 C4.27348295,14 4.04977997,13.9152202 3.88607396,13.759661 L0.238258309,10.3031353 C-0.0891537159,9.99357245 -0.0780114219,9.50122754 0.264828395,9.20333165 C0.605096913,8.90621355 1.14764092,8.91710269 1.47676715,9.22744332 L4.27176875,11.8758389 L10.3708891,0.43834775 C10.576593,0.0533386966 11.0874243,-0.109220682 11.5159741,0.0774503747 C11.9419526,0.264899227 12.1202293,0.72924348 11.9145254,1.11658592 L5.27714652,13.561323 C5.15543838,13.7899951 4.91630761,13.9509988 4.63946446,13.9898887 C4.59489528,13.9968888 4.5503261,14 4.50575693,14' />
      </svg>
    );  
}

