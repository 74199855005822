/** @format */

import React from 'react';
import IconTick from '../../Icons/IconTick';
import checkbox from "../../../styles/components/Utility/Checkbox.module.scss";

export default function CheckBox({checkBoxId, active, handleToggle}) {      

    let classToggle = [`${checkbox.checkbox}` + ' ' +  `${active ? checkbox['checkbox--active'] : checkbox['checkbox--inactive']}`].join(" "); 

    return (
      <div
        className={classToggle} onClick={handleToggle}
        id={checkBoxId && checkBoxId}>
        <IconTick />
      </div>
    );
  }

