import React, { Component } from 'react';
import block from 'bem-css-modules';
import Image from 'next/image';
import footerVariant from '../../styles/components/Footer.module.scss';
import { t, setIdToFooter } from '../../helpers/common';
import { useRouter } from 'next/router';
import LogoVariant from '../LogoVariant';
import { footerLinkId } from '../../helpers/listofid';

export default function Footer({ userDetails, spaceTop }) {
  const { locale } = useRouter();
  const isLoggedIn = userDetails.isLoggedIn;
  block.setSettings({
    modifierDelimiter: '--',
  });
  const navItems = t('lang_footer:navigation', {}, { returnObjects: true });
  const footer = block(footerVariant);
  const currentYear = new Date().getFullYear();
  return (
    <footer className={`${footer('', { footerGift: true })}` + ' ' + `${spaceTop && footerVariant['footerVariant--spaceTop']}`} id='footer'>
      <div className={footer('main', { footerGift: true })}>
        <div className={footer('disclaimer')}>          
          <div className={footer('rightContext')}>            
            <div className={footer('links')}>              
                <a className={footer('linksItem')} href={t('lang_route:investors.about')}>About us</a>
                <a className={footer('linksItem')} href={t('lang_route:investors.disclaimer')}>Investors</a>
                <a className={footer('linksItem')} href={t('lang_route:investors.directors')}>Governance</a>
                <a className={footer('linksItem')} href={t('lang_route:investors.pressreleases')}>Media</a>
                <a className={footer('linksItem')} href={t('lang_route:investors.contact')}>Contact</a>
                <a className={footer('linksItem')} href='https://www.nextory.se/'>Try Nextory</a>
            </div>
          </div>

          <div className={footer('brand')}>
            <div className={footer('brandGroup')}>
            <div className={footer('logo')}>
              <LogoVariant />
            </div>
            <p className={footer('brandText')}>Investors</p>            
            </div>
            <p className={footer('copyright')}>{t('lang_footer:copyright', { year: currentYear })}</p>
          </div>

        </div>
      </div>
    </footer>
  );
}
