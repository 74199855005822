import React from "react";
import block from "bem-css-modules";
import button from "../../styles/components/Button.module.scss";
import card from "../../styles/components/Documentcard.module.scss";
import isEmpty from "lodash/isEmpty";
import moment from "moment";
import ICalendarLink from "react-icalendar-link";

export default function Card({ column, calendar, reports, item, key }) {
  block.setSettings({
    modifierDelimiter: "--",
  });

  const btn = block(button);
  const meet = block(card);

  const event = {
    title: item.Title,
    description: item.Title,
    startTime: moment(item.EventStartDateTime).format("MMMM D, YYYY / H:mm"),
    endTime: moment(item.EventEndDateTime).format("MMMM D, YYYY / H:mm"),
  };

  return (
    <>
      <article
        key={key}
        className={
          `${meet("item")}` + " " + `${column && meet(`item--${column}`)}`
        }
      >
        {!isEmpty(item) && (
          <>
            <span className={meet("date")}>
              {calendar ? (
                <>
                  {moment(item.EventStartDateTime).format(
                    "MMMM D, YYYY / H:mm"
                  )}
                </>
              ) : (
                <>{moment(item.Date).format("DD of MMMM  / YYYY")}</>
              )}
            </span>
            <h3 className={meet("title")}>{item.Title || ""}</h3>
            <div className={meet("links")}>
              {calendar ? (
                <ICalendarLink
                  className={
                    `${btn("", { primary: true, noTransform: true })}` +
                    " " +
                    `${meet("cta")}`
                  }
                  event={event}
                >
                  Add to Calendar
                </ICalendarLink>
              ) : (
                <>
                  {!reports ? (
                    !isEmpty(item.PDF) &&
                    item.PDF.map((pdf, i) => (
                      <a
                        href={pdf?.File?.url || ""}
                        key={i}
                        target="_blank"
                        className={meet("linkItem", { download: true })}
                        
                      >
                        <span className={meet("linkText")}>{pdf.TextPDF}</span>
                      </a>
                    ))
                  ) : (
                    <></>
                  )}

                  {!isEmpty(item.Links) &&
                    item.Links.map((link, i) => (
                      <a target="_blank" href={link.URL} key={i} className={meet("linkItem")}>
                        <span className={meet("linkText")}>
                          {link.LinkName}
                        </span>
                      </a>
                    ))}
                </>
              )}
            </div>
          </>
        )}
      </article>
    </>
  );
}
