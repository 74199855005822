
import React from 'react';
import block from "bem-css-modules";
import loader from "../../../styles/components/Utility/Loader.module.scss";

export default function Loader() { 

  block.setSettings({
    modifierDelimiter: "--",
  });

  const preload = block(loader);
    return (
      <div className={preload('')}>
        <div className={preload('holder')}></div>
      </div>
    );
  }

